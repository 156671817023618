<template>
  <div
    v-bind:style="$vuetify.breakpoint.mdAndDown ? {} : { 'max-width': '75%' }"
  >
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="6"
        v-bind:style="{ height: col_height }"
        style="height: 560px"
      >
        <v-card
          class="offer-box fill-height"
          v-bind:class="
            $vuetify.breakpoint.xs ? 'card-rounded-top' : 'card-rounded-left'
          "
        >
          <v-card-text class="offer-card-text">
            <!--  -->

            <!-- Promozione riservata agli utenti<br /> -->
            <!-- <v-icon style="font-size: 4rem" color="black"
                >mdi-linkedin</v-icon
              > -->
            <!-- LinkedIn -->

            <!-- <hr /> -->
            <h1 style="text-shadow: 2px 2px 1px #d5d100" class="promo">
              Prova SafetyFirst<br />
              <span class="gratis">GRATIS</span>
              <br/>
              per <br />
              <span class="try-days">90</span> giorni<br />
            </h1>
<h3 class="mt-6" style="max-width:500px;">
  Per te inclusa consulenza per la miglior integrazione della soluzione in azienda o come servizio presso i propri clienti
</h3>
            <!-- <h2>
              <p class="plus ma-0 mt-3 mb-1">+</p>
              <br />
            </h2> -->

            <!-- <h2 v-if="show_discount == true">
              <span class="discount-value"> 15%</span>
              <br />
              di sconto <br />per il primo anno!
            </h2>
            <p class="no-creditcard">
              Software in Cloud<br />Nessuna installazione necessaria<br />Non è
              richiesta alcuna carta di credito
            </p> -->

            <p class="no-creditcard">
              Software in Cloud<br />Nessuna installazione<br />
            </p>
            <hr>
            <p>
              Non è
              richiesta alcuna carta di credito
            </p>

          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <cardCollectContactLinkedin elevation="0" card_background="white" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import cardCollectContactLinkedin from "@/components/landings/blocks/cardCollectContactLinkedin.vue";
import { gtag } from "@/gtag";

export default {
  props: {},
  components: {
    cardCollectContactLinkedin,
  },
  data: () => ({
    col_height: 700,
    show_discount: false,
  }),
  methods: {
    showDiscount() {
      gtag("event", "promo_open", {
        // form_id: this.formName,
        // contact_province: this.selectedProvince,
        // contact_region: this.selectedRegion,
        // role: this.selectedRole,
      });
      this.show_discount = true;
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped>

span.gratis {
  margin-top:2rem;
  margin-bottom:2rem;
}

h1.promo {
  font-size:3rem;
  line-height: 1;
}

.plus {
  /* padding-top:1rem;
    padding-bottom:1rem; */
  font-size: 3rem;
  font-weight: bold;
}

.discount-value {
  font-size: 2rem;
}

.offer-box {
  background-color: yellow;
  border-radius: 8px;
  /* box-shadow: 2px 2px 5px black; */
}

.offer-card-text {
  display: flex;
  /* height: 100%; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black !important;
}

.card-rounded-top {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.card-rounded-left {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.no-creditcard,
.promo-detail {
  font-weight: bold;
  font-size:1.4rem;
  line-height:1.2;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: black;
  min-width: 270px;
}
</style>>
