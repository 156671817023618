<template>
  <div>
    <ul class="cb-slideshow">
      <div>
        <span>Image 01</span>
        <div></div>
      </div>
      <div>
        <span>Image 02</span>
        <div></div>
      </div>
      <div>
        <span>Image 03</span>
        <div></div>
      </div>
      <div>
        <span>Image 04</span>
        <div></div>
      </div>
    </ul>

    <div class="body-container">
      <v-row no-gutters class="ma-0" style="position: relative">
        <v-col cols="12" class="logo-row">
          <img
            class="ma-1 mr-3 d-block"
            src="@/assets/graphics/sf-logo-white-yellow_64.png"
            srcset="@/assets/graphics/sf-logo-white-yellow_128.png 2x"
            :height="$vuetify.breakpoint.smAndDown ? '32px' : '64px'"
            contain
            style="background-position: initial !important"
            :to="{
              name: 'LandingHome',
            }"
            alt="SafetyFirst logo"
          />
        </v-col>
        <!-- Porzione headings -->
        <v-col cols="12">
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="6">
              <h1 class="h1-title-big">
                <!-- La soluzione per la sicurezza sul lavoro nella tua azienda! -->
                La soluzione innovativa per prevenire gli infortuni nella tua
                azienda!
              </h1>
              <div style="width: 100%" class="d-flex justify-center"></div>
              <h2
                class="h2-title-big mb-6"
                data-aos="fade-right"
                data-aos-delay="0"
              >
                Coinvolgi e comunica in modo semplice con tutti i tuoi
                collaboratori.
              </h2>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="6"
              class="d-flex justify-center align-center"
            >
              <v-img
                max-width="80%"
                data-aos="fade-left"
                class="ma-3 d-block mb-4"
                :src="header_image"
                contain
                style="background-position: initial !important"
                alt="Mokup App Safety First on multiple devices"
              />
            </v-col>
          </v-row>
          <!-- Promo -->
          <v-row
            id="promo_contact"
            class="promo_contact"
            no-gutters
            style="text-align: -webkit-center"
          >
            <v-col cols="12">
              <promoLinkedin />
            </v-col>
          </v-row>

          <!-- Counter -->
          <v-row
            no-gutters
            class="row-incident-counter pa-6"
            v-observe-visibility="counter_visible"
            id="contatore_incidenti"
          >
            <v-col cols="12" md="8" offset-md="2" class="mb-2 text-center">
              <h2 class="testo-denuncie">
                Denuncie di infortunio da inizio anno ad ora
              </h2>
            </v-col>
            <v-col cols="12" md="8" offset-md="2" class="text-center">
              <flipCounter v-bind:value="event_count" />
            </v-col>
            <v-col cols="12" md="6" offset-md="3" class="text-center">
              <span class="dati-inail"
                >* media secondo i dati Inail per l'anno 2021</span
              >
            </v-col>

            <v-col cols="12" md="8" offset-md="2" class="mt-3 text-center">
              <h3 class="testo-obiettivo">
                L' obiettivo di <span>SafetyFirst</span> è quello di rallentare
                questo contatore, migliorare la salute dei lavoratori e ridurre
                i costi e i danni d'immagine delle aziende
              </h3>
            </v-col>

            <!-- <v-col cols="12" class="col-scopri-come text-center">
              <span class="scopri-come"
                >Scopri come creare cultura e migliorare la sicurezza sul lavoro
                salvaguardando la tua azienda</span
              >
            </v-col>
            <v-col cols="12" class="text-center">
              <v-icon class="freccia-giu">mdi-arrow-down</v-icon>
            </v-col> -->
          </v-row>

          <!--  Video -->
          <v-row
            justify="center"
            class="row-video-player pa-6"
            data-aos="fade-right"
          >
            <v-col
              md="8"
              xl="6"
              cols="12"
              class="text-center white--text pb-0 mb-0"
            >
              <span class="titolo-video"
                >Scopri come è stato possibile <br />evitare un infortunio<br />con
                SafetyFirst</span
              >
            </v-col>
            <v-col md="8" offset-xl="0" xl="8" cols="12">
              <v-card>
                <div class="iframe-container">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/79XfFZIn4ko?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0&autoplay=1"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <!--  -->
        </v-col>
      </v-row>

      <!-- Sezione caratteristiche -->

      <!-- Card user report risk -->
      <!-- 0 -->
      <v-row data-aos="fade-right" no-gutters class="my-2 section-container">
        <v-col cols="12" class="text-center">
          <p class="alcune-caratteristiche">
            Alcune caratteristiche e funzionalità:
          </p>
        </v-col>

        <v-col cols="12">
          <v-card :color="mokup_card_bg1" flat>
            <!-- <v-card-text class="card-text-mokup"> -->
            <v-card-text class="card-text-mokup">
              <v-row no-gutters class="row-section-width">
                <v-col
                  cols="12"
                  sm="6"
                  order-sm="2"
                  md="5"
                  order-md="2"
                  class="col-testi-caratteristica"
                >
                  <h4 class="titolo-caratteristica">
                    Ottieni segnalazioni per le situazioni di pericolo
                  </h4>
                  <p class="paragrafo-caratteristica">
                    Permetti ai tuoi collaboratori di inoltrare, in modo
                    semplice ed intuitivo, avvisi per situazioni ritenute
                    pericolose o di pericolo grave ed immediato.
                  </p>

                  <p class="paragrafo-caratteristica">
                    Agisci per tempo prima che queste condizioni si trasformino
                    in incidenti o infortuni.
                  </p>
                </v-col>

                <v-col
                  class="col-immagine col-immagine-left"
                  cols="12"
                  order-sm="1"
                  sm="6"
                  order-md="1"
                  md="4"
                  offset-md="1"
                >
                  <v-img
                    data-aos="flip-right"
                    class="ma-3 d-block mb-4 mockup-smartphone"
                    :src="user_report_risk_image"
                    contain
                    style="background-position: initial !important"
                    alt="Mokup App Safety First user report risky condition"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Card user withoud devices -->
      <!-- 1  -->
      <v-row data-aos="fade-right" no-gutters class="my-2 section-container">
        <v-col cols="12">
          <v-card :color="mokup_card_bg1" flat>
            <v-card-text class="card-text-mokup">
              <v-row no-gutters class="row-section-width">
                <v-col
                  cols="12"
                  sm="6"
                  order-sm="1"
                  md="5"
                  offset-md="2"
                  order-md="1"
                  class="col-testi-caratteristica"
                >
                  <h4 class="titolo-caratteristica">
                    Comunica in modo efficace con i tuoi collaboratori
                  </h4>
                  <p class="paragrafo-caratteristica">
                    Raggiungi tutti, compreso chi è fuori sede o chi non è
                    dotato di posta elettronica o altri strumenti informatici
                    aziendali.
                  </p>
                  <p class="paragrafo-caratteristica">
                    Permetti, anche alle nuove assunzioni, la consultazione
                    dello storico degli eventi in modo che tutti possano essere
                    preparati al meglio ed evitare infortuni.
                  </p>
                  <p class="paragrafo-caratteristica"></p>
                </v-col>

                <v-col
                  class="col-immagine"
                  sm="6"
                  order-sm="1"
                  md="4"
                  order-md="2"
                  cols="12"
                >
                  <v-img
                    data-aos="flip-left"
                    class="ma-3 d-block mb-4 mockup-smartphone"
                    :src="user_no_device_image"
                    contain
                    style="background-position: initial !important"
                    alt="Mokup App Safety First mobile user"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Card digitalize working areas -->
      <!-- 2 -->
      <v-row data-aos="fade-right" no-gutters class="my-2 section-container">
        <v-col cols="12">
          <v-card :color="mokup_card_bg1" flat>
            <v-card-text class="card-text-mokup">
              <v-row no-gutters class="row-section-width">
                <v-col
                  cols="12"
                  sm="6"
                  order-sm="2"
                  md="5"
                  order-md="2"
                  class="col-testi-caratteristica"
                >
                  <h4 class="titolo-caratteristica">
                    Digitalizza le aree di lavoro
                  </h4>
                  <p class="paragrafo-caratteristica">
                    Rendi le aree di lavoro digitali e dinamiche dal punto di
                    vista della sicurezza sul lavoro veicolando informazioni e
                    avvisi.
                  </p>

                  <p class="paragrafo-caratteristica">
                    Utilizza segnaletica digitale per definire le prescrizioni,
                    i rischi e i divieti per ciascuna area.
                  </p>
                </v-col>

                <v-col
                  class="col-immagine col-immagine-left"
                  cols="12"
                  sm="6"
                  order-sm="1"
                  md="4"
                  order-md="1"
                  offset-md="1"
                >
                  <v-img
                    data-aos="flip-right"
                    class="ma-3 d-block mb-4 mockup-ipad"
                    :src="digitalize_areas_image"
                    contain
                    style="background-position: initial !important"
                    alt="Mokup App Safety First factory area comunications"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Card Events creation -->
      <!-- 3  -->
      <v-row data-aos="fade-right" no-gutters class="my-2 section-container">
        <v-col cols="12">
          <v-card :color="mokup_card_bg1" flat>
            <v-card-text class="card-text-mokup">
              <v-row no-gutters class="row-section-width">
                <v-col
                  order-md="1"
                  cols="12"
                  sm="6"
                  order-sm="1"
                  md="5"
                  offset-md="2"
                  class="col-testi-caratteristica"
                >
                  <h4 class="titolo-caratteristica">
                    Documenta gli episodi di infortunio o mancato incidente
                  </h4>
                  <p class="paragrafo-caratteristica">
                    Crea bollettini di sicurezza, allega immagini e didascalie
                    per descrivere al meglio gli eventi ed evitare che questi si
                    ripetano.
                  </p>
                  <p class="paragrafo-caratteristica">
                    Ambiente aziendale multilingue?
                    <br />
                    Nessun problema, crea traduzioni per rendere il messaggio
                    comprensibile da tutti.
                  </p>
                </v-col>

                <v-col
                  class="col-immagine"
                  sm="6"
                  order-sm="2"
                  order-md="2"
                  cols="12"
                  md="5"
                >
                  <v-img
                    data-aos="flip-left"
                    class="ma-3 d-block mb-4 mockup-macbook"
                    :src="create_event_image"
                    contain
                    style="background-position: initial !important"
                    alt="Mokup App Safety First events creation"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Card dashboards -->
      <!-- 4 -->
      <v-row data-aos="fade-right" no-gutters class="my-2 section-container">
        <v-col cols="12">
          <v-card :color="mokup_card_bg1" flat>
            <v-card-text class="card-text-mokup">
              <v-row no-gutters class="row-section-width">
                <v-col
                  cols="12"
                  sm="6"
                  order-sm="2"
                  md="5"
                  order-md="2"
                  class="col-testi-caratteristica"
                >
                  <h4 class="titolo-caratteristica">
                    Mostra lo stato della sicurezza
                  </h4>
                  <p class="paragrafo-caratteristica">
                    Visualizza i giorni senza incidenti, medicazioni o infortuni
                    evidenziando l'attenzione che tu e la tua azienda riponete
                    nella sicurezza delle aree di lavoro.
                  </p>
                  <p class="paragrafo-caratteristica">
                    Dai modo ai tuoi clienti, fornitori e visitatori di
                    conoscere il tuo impegno per garantire un luogo di lavoro
                    sicuro.
                  </p>
                </v-col>

                <v-col
                  class="col-immagine col-immagine-left"
                  cols="12"
                  sm="6"
                  order-sm="1"
                  md="4"
                  order-md="1"
                  offset-md="1"
                >
                  <v-img
                    data-aos="flip-right"
                    class="ma-3 d-block mb-4 mockup-dashboard"
                    :src="dashboard_image"
                    contain
                    alt="Mokup App Safety First dashboard"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12">
          <v-card
            height="200"
            class="d-flex align-center justify-center"
            color="black"
            @click="scroll_to_promo_contact()"
            style="
              border-style: solid;
              border-color: yellow !important;
              border-width: 4px;
            "
          >
            <v-card-text class="text-center">
              <v-row>
                <v-col cols="12">
                  <strong class="voglio-provarlo yellow--text">
                    Scopri di più su
                  </strong>
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                  <img
                    class="ma-1 mr-3 d-block"
                    src="@/assets/graphics/sf-logo-white-yellow_64.png"
                    srcset="@/assets/graphics/sf-logo-white-yellow_128.png 2x"
                    :height="$vuetify.breakpoint.smAndDown ? '64px' : '96px'"
                    contain
                    style="background-position: initial !important"
                    :to="{
                      name: 'LandingHome',
                    }"
                    alt="SafetyFirst logo"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters style="background: #000000a3" class="mt-12 py-8">
        <v-col cols="12" class="d-flex justify-center">
          <a href="https://avmsquare.com" target="_blank">
            <img
              class="d-block"
              src="@/assets/graphics/avm2_logo_rgb_fondo_trasp_48dpi_r.png"
              srcset="
                @/assets/graphics/avm2_logo_rgb_fondo_trasp_96dpi_r.png 2x
              "
              contain
              style="background-position: initial !important; height: 48px"
              alt="AVMSquare logo"
            />
          </a>
        </v-col>
        <v-col cols="12" class="d-flex justify-center text-center">
          <span class="white--text" style="font-size: 0.75rem">
            AVMSquare di Alessandro Vittorio Mendolia <br />
            P.IVA: 03931380046 - REA: CN-324922
          </span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import "@/styles/landing2.css";
import "@/styles/background_animation.css";
import promoLinkedin from "@/components/landings/blocks/promoLinkedin.vue";
import flipCounter from "@/components/landings/blocks/flipCounter.vue";

import { i18n } from "@/plugins/i18n";
import AOS from "aos";
import "aos/dist/aos.css";
import { EventBus } from "@/main";

function getOffsetTop(el) {
  const rect = el.getBoundingClientRect();
  return rect.top + window.scrollY;
}

function msSinceStartOfYear() {
  var now = new Date();
  return now - new Date(now.getFullYear(), 0);
}

export default {
  metaInfo: {
    title: "Promozione Utenti LinkedIn",
    meta: [
      {
        name: "viewport",
        content: "width=device-width,initial-scale=1,user-scalable:1",
      },
      {
        name: "description",
        content: "Promozione SafetyFirst riservata agli utenti LinkedIn",
      },
      {
        name: "robots",
        content: "noindex",
      },
    ],
  },

  props: {},
  components: {
    promoLinkedin,
    flipCounter,
  },
  data: () => ({
    event_count: 0,
    secondi_in_un_anno: 31536000,
    infortuni_2021: 555236,
    // mokup_card_bg1: "#ffffffcc",
    mokup_card_bg1: "transparent",
    // user_no_device_images_index: 1,
    user_no_device_image: require("@/assets/graphics/devices-mockup/smartphone_user_02.png"),
    digitalize_areas_image: require("@/assets/graphics/devices-mockup/ipad_area_01.png"),
    create_event_image: require("@/assets/graphics/devices-mockup/macbook_event_create_01.png"),
    header_image: require("@/assets/graphics/devices-mockup/smartphone-macbook-ipad_01.png"),
    user_report_risk_image: require("@/assets/graphics/devices-mockup/smartphone_user_report_01.png"),
    dashboard_image: require("@/assets/graphics/devices-mockup/wall_monitor_01.png"),
  }),
  methods: {
    open_cookie_settings() {
      console.log("open cookie settings");
      EventBus.$emit("open_cookie_setting");
    },

    counter_visible(isVisible, entry) {
      this.isVisible = isVisible;
      if (entry.target.id == "contatore_incidenti") {
        setTimeout(() => {
          this.initEventCounter();
        }, 500);
      }
    },

    scroll_to_promo_contact() {
      // document.querySelector("#promo_contact").scrollIntoView({
      //   behavior: "smooth",
      //    block: "start"
      // });
      const el = document.getElementById("promo_contact");
      if (el) {
        let distanceFromTop = el.getBoundingClientRect().top;
        console.log(distanceFromTop);
        let appBarHeight = 600;
        let scroll_position = distanceFromTop - appBarHeight;
        window.scrollTo({ top: getOffsetTop(el) - 25, behavior: "smooth" });
      }
    },
    initEventCounter() {
      // -----------------------------------------------
      // Contatore animato numero eventi da inizio anno
      // -----------------------------------------------
      let secondsFromYearBegin = Math.floor(msSinceStartOfYear() / 1000);
      let stop_flip = Math.floor(
        (this.infortuni_2021 * secondsFromYearBegin) / this.secondi_in_un_anno
      );
      let start_flip = stop_flip - 1200;
      this.event_count = start_flip;
      if (this.event_count < 0) this.event_count = 0;
      let timer_flip_start1 = setInterval(() => {
        if (this.event_count < stop_flip) {
          this.event_count = this.event_count + 121;
          if (this.event_count < 0) this.event_count = 0;
          if (this.event_count > stop_flip) this.event_count = stop_flip;
        } else {
          clearTimeout(timer_flip_start1);
          this.startAutoUpdateEventCount();
        }
      }, 200);
      // -----------------------------------------------
    },
    startAutoUpdateEventCount() {
      setInterval(() => {
        this.event_count = 0;
        let secondsFromYearBegin = Math.floor(msSinceStartOfYear() / 1000);
        // console.log((this.infortuni_2021 * secondsFromYearBegin) / this.secondi_in_un_anno);
        this.event_count = Math.floor(
          (this.infortuni_2021 * secondsFromYearBegin) / this.secondi_in_un_anno
        );
      }, 1000);
    },
  },
  mounted() {
    AOS.init();
    i18n.locale = "it";
    // this.rotate_user_no_device_images();
  },
  created() {},
  computed: {
    sf_logo_bw() {
      return require("@/assets/graphics/sf-logo-black-yellow_64.png");
    },
    sf_logo_bw_2x() {
      return require("@/assets/graphics/sf-logo-black-yellow_128.png") + " 2x";
    },
    sf_logo_w() {
      return require("@/assets/graphics/sf-logo-white-yellow_64.png");
    },
    sf_logo_w_2x() {
      return require("@/assets/graphics/sf-logo-white-yellow_128.png") + " 2x";
    },
  },
  watch: {},
};
</script>



<style lang="scss" scoped>
.titolo-caratteristica {
  font-size: 2rem;
  // color: black;
  color: white !important;
  text-shadow: 2px 2px 2px #00000061;
  text-shadow: 2px 3px 4px black;
  line-height: 0.95;
}

.section-container {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.paragrafo-caratteristica {
  margin-top: 1rem;
  font-size: 1.2rem;
  color: white !important;
  text-shadow: 2px 3px 4px black;
}

.card-text-mokup {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.col-video {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.col-testi-caratteristica {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.h1-title {
  text-shadow: 2px 2px 3px #00000059;
}

.dati-inail {
  font-size: 0.95rem;
  text-shadow: 2px 2px 2px black;
}

.body-container {
  z-index: 2;
  // background-color: #f8f8f8c4;
  // box-shadow: 5p -x 5px 8px black;
  // margin: auto;
  color: white;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.logo-row {
  // background: linear-gradient(90deg, black, transparent);
}

/* small screen */
@media only screen and (max-width: 600px) {
  .titolo-video {
    font-size: 1.2rem;
    font-weight: bold;
    text-shadow: 3px 3px 4px black;
  }

  .alcune-caratteristiche {
    font-size: 1.2rem;
    font-weight: bold;
    text-shadow: 3px 3px 4px black;
  }

  .body-container {
    width: 95vw;
    min-height: 95vh;
    top: 1rem;
    border-radius: 10px;
    padding-bottom: 2rem;
  }

  .h1-title-big {
    margin-top: 3rem;
    font-size: 2.8rem;
    line-height: 0.9;
    text-shadow: 3px 3px 4px black;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .h2-title-big {
    margin-top: 2rem;
    font-size: 1.8rem;
    line-height: 1.2;
    text-shadow: 2px 2px 4px black;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .promo_contact {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .row-incident-counter {
    margin-top: 5rem;
    margin-bottom: 5rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 8px;
    padding-right: 8px;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 20px;
  }

  .row-video-player {
    margin-top: 5rem;
    margin-bottom: 5rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 8px;
    padding-right: 8px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
  }

  .testo-denuncie {
    font-size: 1.5rem !important;
    text-shadow: 2px 2px 2px black, 4px 4px 5px black;
  }
  .dati-inail {
    font-size: 0.75rem;
    text-shadow: 2px 2px 2px black;
  }
  .testo-obiettivo {
    font-size: 1.2rem !important;
    font-weight: normal;
    text-shadow: 2px 2px 2px black, 4px 4px 5px black;
  }

  .scopri-come {
    font-weight: 800;
    font-size: 1.1rem;
    text-shadow: 2px 2px 2px black, 0px 0px 2px yellow, 4px 4px 5px black;
  }

  .freccia-giu {
    color: white;
    margin-top: 2rem;
    text-shadow: 2px 2px 2px black, 4px 4px 5px black;
    font-size: 6rem;
  }

  .col-scopri-come {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  .mockup-smartphone {
    max-width: 60vw;
  }

  .mockup-ipad {
    max-width: 60vw;
  }

  .mockup-macbook {
    max-width: 80vw;
  }

  .mockup-dashboard {
    max-width: 78vw;
  }

  .no-creditcard {
    font-size: 0.9rem;
    font-weight: normal;
  }

  .col-immagine {
    display: flex;
    justify-content: center;
  }

  .voglio-provarlo {
    font-size: 1.6rem;
  }
}

/* large screen */
@media only screen and (min-width: 600px) {
  .titolo-video {
    font-size: 2rem;
    font-weight: bold;
    text-shadow: 3px 3px 4px black;
  }

  .alcune-caratteristiche {
    font-size: 2rem;
    font-weight: bold;
    text-shadow: 3px 3px 4px black;
  }

  .body-container {
    width: 80vw;
    top: 1rem;
    transform: translateX(-50%);
    border-radius: 20px;
    padding-bottom: 2rem;
  }

  .h1-title-big {
    margin-top: 4rem;
    font-size: 4rem;
    line-height: 0.9;
    text-shadow: 4px 4px 4px black;
  }

  .h2-title-big {
    margin-top: 3rem;
    font-size: 2rem;
    line-height: 1.2;
    text-shadow: 2px 2px 4px black;
  }

  .promo_contact {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .row-incident-counter {
    margin-top: 10rem;
    margin-bottom: 10rem;
    padding-top: 5rem;
    padding-bottom: 3rem;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 75px;
  }

  .row-video-player {
    margin-top: 5rem;
    margin-bottom: 5rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 75px;
  }

  .testo-denuncie {
    font-size: 1.6rem !important;
    text-shadow: 2px 2px 2px black, 4px 4px 5px black;
  }

  .testo-obiettivo {
    font-size: 1.2rem !important;
    font-weight: normal;
    text-shadow: 2px 2px 2px black, 4px 4px 5px black;
  }

  .freccia-giu {
    color: white;
    margin-top: 2rem;
    text-shadow: 2px 2px 2px black, 4px 4px 5px black;
    font-size: 6rem;
  }

  .scopri-come {
    font-weight: 400;
    font-size: 1.6rem;
    text-shadow: 2px 2px 2px black, 0px 0px 2px yellow, 4px 4px 5px black;
  }

  .col-scopri-come {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .row-section-width {
    width: 100%;
  }

  .mockup-smartphone {
    max-width: 200px;
  }

  .mockup-ipad {
    max-width: 250px;
  }

  .mockup-macbook {
    max-width: 800px;
  }

  .mockup-dashboard {
    max-width: 100%;
  }

  .col-testi-caratteristica {
    width: 250px;
  }

  .col-immagine-left {
    display: flex;
    justify-content: flex-end;
  }
  .voglio-provarlo {
    font-size: 1.6rem;
  }
}
</style>


